// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-service-js": () => import("./../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-swarms-js": () => import("./../src/templates/swarms.js" /* webpackChunkName: "component---src-templates-swarms-js" */),
  "component---src-templates-about-js": () => import("./../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-bees-index-js": () => import("./../src/pages/bees/index.js" /* webpackChunkName: "component---src-pages-bees-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-swarms-index-js": () => import("./../src/pages/swarms/index.js" /* webpackChunkName: "component---src-pages-swarms-index-js" */),
  "component---src-pages-track-index-js": () => import("./../src/pages/track/index.js" /* webpackChunkName: "component---src-pages-track-index-js" */)
}

